import { FunctionComponent } from 'react'
import { FeaturePageTemplate, TFeatureItem } from './_featurePageTemplate'
import relationDemo from './imgs/relations/relationDemo.gif'
import grammer from './imgs/relations/grammer.png'
const items: TFeatureItem[] = [
  {
    title: 'Drag And Drop Relationships',
    description:
      'LightTag is the only tool that lets you form any entity relationship with drag and drop. No more lines across the screen, work faster and express your data with the right UX.',
    img: relationDemo,
  },
  {
    title: 'Constituency and Dependency Grammers',
    description:
      'LightTag assigns work to your team automatically. You can specify multiple projects with different annotators and we;ll coordinate it for you. ',
    img: grammer,
  },
]
const RelationshipAnnotation: FunctionComponent = () => {
  return (
    <FeaturePageTemplate
      header={'Relationship Annotation'}
      subheader={
        'Relationship, coreference and dependency annotation made easy.'
      }
      googleDescription={
        "'Relationship, coreference and dependency annotation made easy.'"
      }
      googleKeywords={[
        'Dependency annotations',
        'relationship annotations',
        'coreference annotation',
      ]}
      featureItems={items}
    />
  )
}

export default RelationshipAnnotation
